import React from "react";
import "./App.css";
import "./assets/css/style.css";
import "./index.scss";
import "react-phone-input-2/lib/bootstrap.css";
import Routes from "./app/routes";
import { useDispatch, useSelector } from "react-redux";
import { setLoggedIn, setToken } from "./app/store/Auth";
import { useEffect } from "react";
import Loader from "./app/Layout/Loader/Loader";

function App() {
  const dispatch = useDispatch();

  function startLogoutTimer() {
    const timeout = 24 * 60 * 60 * 1000;
    const timer = setTimeout(logout, timeout);
    localStorage.setItem("logoutTimer", timer);
  }

  function logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("email");
    window.location.href = "/login";
  }

  function resetLogoutTimer() {
    const timer = localStorage.getItem("logoutTimer");
    if (timer) {
      clearTimeout(parseInt(timer));
      startLogoutTimer();
    }
  }

  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(setToken(localStorage.getItem("token")));
    }
    if (localStorage.getItem("isLoggedIn")) {
      dispatch(setLoggedIn(localStorage.getItem("isLoggedIn")));
    }
    startLogoutTimer();
    resetLogoutTimer();
  }, []);

  const { isLoggedIn } = useSelector((state) => state.auth);

  const isLoggedin = isLoggedIn || localStorage.getItem("token");

  return (
    <React.Suspense fallback={<Loader />}>
      <Routes isLoggedIn={isLoggedin} />
    </React.Suspense>
  );
}

export default App;
