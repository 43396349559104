import serverCall from "../../serverCall";

const getAllBrands = async () => {
  const response = await serverCall.get(`/brand/All-Brand-List/admin`);
  return response;
};

const addBrand = async (brandName, brand, carModel) => {
  const response = await serverCall.post(
    `/brand/addPhotos/admin?brandName=${brandName}&CarModel=${carModel}`,
    brand
  );
  return response;
};

const updateBrand = async (brandId, brandName, brand, CarModel) => {
  const response = await serverCall.post(
    `/brand/updateDetails/admin?brandId=${brandId}&brandName=${brandName}&CarModel=${CarModel}`,
    brand
  );
  return response;
};

const findOneBrand = async (id) => {
  const response = await serverCall.get(`/brand/Find-One-Brand?BrandId=${id}`);
  return response;
};

const deleteImage = (id) => {
  const response = serverCall.delete(`/brand/Delete-image/admin?BrandId=${id}`);
  return response;
};

const deleteBrand = async (id) => {
  const response = await serverCall.delete(
    `/brand/Delete-Brand/admin?BrandId=${id}`
  );
  return response;
};

const BrandService = {
  getAllBrands,
  addBrand,
  deleteBrand,
  updateBrand,
  findOneBrand,
  deleteImage,
};

export default BrandService;
