import { InputAdornment } from "@mui/material";
import { collection, getDocs, onSnapshot, query } from "firebase/firestore";
import React, { Fragment, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { db } from "../../../firebase";
import withLoader from "../../Layout/Loader/WithLoader";

const UserChatList = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const AdminId = state?.AdminId;
  console.log("AdminId", AdminId);
  const [chatData, setChatData] = useState([]);
  const [searchString, setSearchString] = useState("");
  const handlesearch = (e) => {
    setSearchString(e.target.value);
  };

  const getList = async () => {
    const unsub = onSnapshot(query(collection(db, "Users")), (snapshot) => {
      const chatList = [];
      snapshot.forEach((doc) => {
        const data = doc.data();
        if (data.userId === id) {
          console.log(data);
          const chatRoomValues = Object.values(data.chatRoom);
          chatRoomValues.map((chat) => {
            console.log("Matched object:", chat);
            chatList.push(chat);
          });
        }
      });
      setChatData(chatList);
    });
    return unsub;
  };

  console.log("chatData", chatData);

  useEffect(() => {
    getList();
  }, []);

  return (
    <Fragment>
      <div className="page-header">
        <h2 className="main-content-title tx-28 mt-2">Chatlist</h2>

        <Card>
          <Card.Body>
            {chatData.length > 0 ? (
              <>
                <div className="d-flex add-search">
                  <div className="d-flex align-items-center md-form mt-auto mb-2">
                    <input
                      type="search"
                      className="form-control form-control-md ml-3 py-3"
                      value={searchString}
                      onChange={handlesearch}
                      placeholder="Search"
                      inputprops={{
                        startAdornment: (
                          <InputAdornment position="start"></InputAdornment>
                        ),
                      }}
                    ></input>
                    <i className="fa fa-search ms-2"></i>
                  </div>
                </div>
                <div className="chats">
                  {chatData
                    .filter((row) => {
                      const combinedString = `${row.brandName} ${row.ownerName} ${row.lastMessage}`;
                      return combinedString
                        .toLowerCase()
                        .includes(searchString);
                    })
                    .sort((a, b) => b.lastMessageTime - a.lastMessageTime)
                    .map((chat) => (
                      <Link
                        key={chat.id}
                        className="userChat"
                        to={`/carowner/carownerprofile/chatlist/chat/${
                          id +
                          "=" +
                          chat.senderId.split("=")[0] +
                          "=" +
                          chat.carId
                        }`}
                        state={{
                          ownerName: chat.senderId.split("=")[0],
                          AdminId: AdminId,
                        }}
                      >
                        <img
                          src={chat.carImage ? chat.carImage[0] : ""}
                          alt=""
                        />
                        <div className="userChatInfo">
                          <span>{chat.brandName}</span>
                          <p
                            className="mb-0 text-dark"
                            style={{ fontSize: "12px" }}
                          >
                            {chat.ownerName}
                          </p>
                          <p
                            className="mb-2"
                            style={{ fontSize: "15px", fontWeight: "600" }}
                          >
                            {chat.lastMessage}
                          </p>
                        </div>
                      </Link>
                    ))}
                </div>
              </>
            ) : (
              <div className="text-danger tx-20 text-center">
                Chat Not Found
              </div>
            )}
          </Card.Body>
        </Card>
      </div>
    </Fragment>
  );
};

export default withLoader(UserChatList);
