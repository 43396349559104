import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import AuthService from "../services/auth.service";

const initialState = {
  loading: false,
  token: null,
  error: false,
  user: null,
  isLoggedIn: !!JSON.parse(localStorage.getItem("isLoggedIn")),
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
      state.token = get(action.payload, "token", null);
      localStorage.setItem(
        "token",
        JSON.stringify(get(action.payload, "token", null))
      );
    },
  },
});

export const login = createAsyncThunk(
  "/admin/login-admin",
  async (requestObj, thunkAPI) => {
    try {
      const response = await AuthService.Login(requestObj);
      const token = get(response, "jwtToken", null);
      localStorage.setItem("token", response.data.accessToken);
      return token;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        get(error, "response.data.apierror", error)
      );
    }
  }
);

const { reducer, actions } = authSlice;

export const { setToken, setUser, setLoggedIn } = actions;
export default reducer;
