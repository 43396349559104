import React, { Suspense } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import { lazy } from "react";
import withLoader from "./Layout/Loader/WithLoader";
import BrandDetails from "./Components/Brands/BrandDetails";
import Currency from "./Components/Currency/Currency";
import TransactionDetails from "./Components/CarOwner/TransactionDetails";
import UserChatList from "./Components/Users/UserChatList";

const Loading = (Component) => (props) =>
  (
    <Suspense fallback={<withLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Apps = Loading(lazy(() => import("./Components/Application")));
const CallHistory = Loading(
  lazy(() => import("./Components/Cars/CallHistory"))
);
const Report = Loading(lazy(() => import("./Components/Report/Report")));
const Chats = Loading(lazy(() => import("./Components/Report/Chats")));
const AddAdminCars = Loading(
  lazy(() => import("./Components/Cars/AddAdminCars"))
);
const Dashboard = Loading(
  lazy(() => import("./Components/Dashboard/Dashboard"))
);
const AdminCarChat = Loading(
  lazy(() => import("./Components/Chat/AdminCarChat"))
);
const AuthLogin = Loading(
  lazy(() => import("./Components/Authentication/Login"))
);
const CarDetails = Loading(lazy(() => import("./Components/Cars/CarDetails")));
const CarOwnerProfile = Loading(
  lazy(() => import("./Components/CarOwner/CarOwnerProfile"))
);
const UserProfile = Loading(
  lazy(() => import("./Components/Users/UserProfile"))
);
const Category = Loading(lazy(() => import("./Components/Category/Category")));
const Brands = Loading(lazy(() => import("./Components/Brands/Brands")));
const Cars = Loading(lazy(() => import("./Components/Cars/Cars")));
const Users = Loading(lazy(() => import("./Components/Users/Users")));
const CarOwner = Loading(lazy(() => import("./Components/CarOwner/CarOwner")));
const Locations = Loading(
  lazy(() => import("./Components/Locations/Locations"))
);
const OwnerChatList = Loading(
  lazy(() => import("./Components/CarOwner/OwnerChatList"))
);

function RedirectionWrapper({ to }) {
  const queryString = window.location.search;
  if (queryString) {
    return <Navigate to={`${to}${queryString}`} />;
  }
  return <Navigate to={to} />;
}

const routes = (isLoggedIn) => [
  {
    path: "/",
    element: isLoggedIn ? <Apps /> : <RedirectionWrapper to="/login" />,
    children: [
      {
        index: true,
        element: <RedirectionWrapper to="/dashboard/" />,
      },
      {
        path: "/dashboard/",
        element: <Dashboard />,
      },
      {
        path: "/carowner",
        element: <CarOwner />,
      },
      {
        path: "/users",
        element: <Users />,
      },
      {
        path: "/cars",
        element: <Cars />,
      },
      {
        path: "/addcar",
        element: <AddAdminCars />,
      },
      {
        path: "/cars/cardetails/:id",
        element: <CarDetails />,
      },
      {
        path: "/carowner/carownerprofile/:id",
        element: <CarOwnerProfile />,
      },
      {
        path: "/carowner/carownerprofile/chatlist/:id",
        element: <OwnerChatList />,
      },
      {
        path: "/carowner/carownerprofile/chatlist/chat/:id",
        element: <AdminCarChat />,
      },
      {
        path: "/users/userprofile/chatlist/:id",
        element: <UserChatList />,
      },
      {
        path: "/users/userprofile/chatlist/chat/:id",
        element: <AdminCarChat />,
      },
      {
        path: "/carowner/carownerprofile/:id/callHistory/:type",
        element: <CallHistory />,
      },
      {
        path: "/users/userprofile/:id",
        element: <UserProfile />,
      },
      {
        path: "/brands",
        element: <Brands />,
      },
      {
        path: "/brands/branddetails/:id",
        element: <BrandDetails />,
      },
      {
        path: "/category",
        element: <Category />,
      },
      {
        path: "/report",
        element: <Report />,
      },
      {
        path: "/report/chats/:id",
        element: <Chats />,
      },

      {
        path: "/locations",
        element: <Locations />,
      },
      {
        path: "/currency",
        element: <Currency />,
      },
      {
        path: "/transactions",
        element: <TransactionDetails />,
      },
    ],
  },
  {
    path: "/login",
    element: !isLoggedIn ? (
      <AuthLogin />
    ) : (
      <RedirectionWrapper to="/dashboard/" />
    ),
  },
];

export default function Routes(props) {
  const { isLoggedIn } = props;
  return useRoutes(routes(isLoggedIn));
}
