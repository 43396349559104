import serverCall from "../../serverCall";

const addModel = async (
  brandId,
  categoryId,
  Carmodel,
  seat,
  door,
  BPH,
  maxspeed,
  fuel,
  geartype,
  day,
  weekly,
  monthly,
  monthlykm,
  daykm,
  weeklykm,
  age,
  insurence,
  CDW,
  VAT,
  charge,
  claim,
  toll
) => {
  const response = await serverCall.post(`/car-value/create-car-information`, {
    brandId,
    categoryId,
    Carmodel,
    seat,
    door,
    BPH,
    maxspeed,
    fuel,
    geartype,
    day,
    weekly,
    monthly,
    monthlykm,
    daykm,
    weeklykm,
    age,
    insurence,
    CDW,
    VAT,
    charge,
    claim,
    toll,
  });
  return response;
};

const updateModel = (
  id,
  brandId,
  categoryId,
  Carmodel,
  seat,
  door,
  BPH,
  maxspeed,
  fuel,
  geartype,
  day,
  weekly,
  monthly,
  monthlykm,
  daykm,
  weeklykm,
  age,
  insurence,
  CDW,
  VAT,
  charge,
  claim,
  toll
) => {
  const response = serverCall.put(
    `/car-value/update-car-information/{id}?id=${id}`,
    {
      brandId,
      categoryId,
      Carmodel,
      seat,
      door,
      BPH,
      maxspeed,
      fuel,
      geartype,
      day,
      weekly,
      monthly,
      monthlykm,
      daykm,
      weeklykm,
      age,
      insurence,
      CDW,
      VAT,
      charge,
      claim,
      toll,
    }
  );
  return response;
};

const getAllModels = (brandName) => {
  const response = serverCall.get(`/car-value?brand=${brandName}`);
  return response;
};

const deleteModel = (id) => {
  const response = serverCall.delete(`/car-value/${id}`);
  return response;
};

const modelValue = async (id) => {
  const response = await serverCall.get(`/car-value/${id}`);
  return response;
};

const carFindByBrand = (Carmodel, brand, category) => {
  const response = serverCall.post(`/car-value/car-find-by-brand`, {
    Carmodel,
    brand,
    category,
  });
  return response;
};

const ModelService = {
  addModel,
  getAllModels,
  modelValue,
  updateModel,
  deleteModel,
  carFindByBrand,
};

export default ModelService;
