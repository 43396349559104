import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBvm70kitt_Wo3yk962ra8l_uOjEpayemA",
  authDomain: "drive-now-8cdb9.firebaseapp.com",
  projectId: "drive-now-8cdb9",
  storageBucket: "drive-now-8cdb9.appspot.com",
  messagingSenderId: "746911019718",
  appId: "1:746911019718:web:b90e671dc2baa8c44c5dc7",
  measurementId: "G-E7PSVWEF8V",
};

const app = initializeApp(firebaseConfig);

//initialize Cloud Functions through Firebase
const auth = getAuth();

const storage = getStorage();

const db = getFirestore(app);

export { app, auth, collection, db, doc, setDoc, storage };
