// import { useEffect, useState } from "react";
// import Loader from "./Loader";

// const withLoader = (WrappedComponent) => {
//   return () => {
//     const [loading, setLoading] = useState(true);

//     useEffect(() => {
//       const timeout = setTimeout(() => {
//         setLoading(false);
//       }, 500);

//       return () => {
//         clearTimeout(timeout);
//       };
//     }, []);

//     if (loading) {
//       return <Loader />;
//     }

//     return <WrappedComponent />;
//   };
// };

// export default withLoader;

import { useEffect, useState } from "react";
import Loader from "./Loader";

const withLoader = (WrappedComponent) => {
  return () => {
    const [loading, setLoading] = useState(true);
    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
      const handleBeforeUnload = () => {
        setShowLoader(false);
      };

      window.addEventListener("beforeunload", handleBeforeUnload);

      const timeout = setTimeout(() => {
        setLoading(false);
      }, 1000);

      return () => {
        clearTimeout(timeout);
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }, []);

    return (
      <>
        {showLoader && loading && <Loader />}
        <WrappedComponent />
      </>
    );
  };
};

export default withLoader;
