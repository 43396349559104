import React, { useEffect, useState } from "react";
import withLoader from "../../Layout/Loader/WithLoader";
import { useParams } from "react-router-dom";
import BrandService from "../../services/brand.service";
import { useFormik } from "formik";
import AddModel, { DeleteModel } from "./AddModel";
import * as yup from "yup";
import ModelService from "../../services/model.service";
import CategoryService from "../../services/category.service";
import { Card, Col, Modal, Row } from "react-bootstrap";
import useDialogState from "../../hooks/useDialog";
import { ScrollToTop } from "../Common/ScrollToTop";

const BrandDetails = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [category, setCategory] = useState([]);
  const [models, setModels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [update, setUpdate] = useState(false);
  const [view, setView] = useState(false);
  const [selectedModelId, setSelectedModelId] = useState("");
  const [selectedModelValue, setSelectedModelValue] = useState("");

  const [selectedCategory, setSelectedCategory] = useState("");
  const [newCategory, setNewCategory] = useState(
    selectedModelValue ? selectedModelValue.category : ""
  );

  const [newInsurence, setNewInsurence] = useState(
    selectedModelValue
      ? selectedModelValue.insurence
        ? { value: "true", label: "Yes" }
        : { value: "false", label: "No" }
      : ""
  );
  const [newCDW, setNewCDW] = useState(
    selectedModelValue
      ? selectedModelValue.CDW
        ? { value: "true", label: "Yes" }
        : { value: "false", label: "No" }
      : ""
  );

  const {
    open: isDialogOpen,
    handleClickOpen: handleDialogOpen,
    handleClose: handleDialogClose,
  } = useDialogState();

  const error = {
    marginTop: "8px",
    color: "#FD481B",
    fontSize: "13px",
    fontWeight: "500px",
  };

  useEffect(() => {
    brandDetail();
    allCategory();
  }, []);

  const dropdown = [
    {
      value: "true",
      label: "Yes",
    },
    {
      value: "false",
      label: "No",
    },
  ];

  const categories = [
    ...category.map((item) => ({
      value: item.id,
      label: item.categoryname,
    })),
  ];

  const handleChangeCategory = (selectedOption) => {
    const selectCategory = categories.find(
      (category) => category.value === selectedOption.value
    );

    setNewCategory(selectCategory);
    formik.setFieldValue("categoryId", selectedOption.value);
    setSelectedCategory(selectedOption);
  };

  const seletctInsurence = (selected) => {
    formik.setFieldValue("insurence", selected.value);
    const selectedInsurence = dropdown.find(
      (insurence) => insurence.value === selected.value
    );
    setNewInsurence(selectedInsurence);
  };

  const selectCDW = (selected) => {
    formik.setFieldValue("CDW", selected.value);
    const selectedCDW = dropdown.find((CDW) => CDW.value === selected.value);
    setNewCDW(selectedCDW);
  };

  const formik = useFormik({
    initialValues: {
      categoryId: "",
      Carmodel: "",
      seat: "",
      door: "",
      BPH: "",
      maxspeed: "",
      fuel: "",
      geartype: "",
      day: "",
      weekly: "",
      monthly: "",
      monthlykm: "",
      daykm: "",
      weeklykm: "",
      age: "",
      insurence: "",
      CDW: "",
      VAT: "",
      charge: "",
      claim: "",
      toll: "",
    },

    validationSchema: yup.object({
      categoryId: yup.string().required("Category Name is Required"),
      Carmodel: yup.string().required("Model Name is Required"),
      seat: yup.number().required("Seat is Required"),
      door: yup.number().required("Door is Required"),
      BPH: yup.number().required("BHP are required"),
      maxspeed: yup.number().required("Max Speed is required"),
      fuel: yup.string().required("Fuel Name is Required"),
      geartype: yup.string().required("Gear Type is required"),
      day: yup.number().required("day is Required"),
      weekly: yup.number().required("weekly is Required"),
      monthly: yup.number().required("monthly is Required"),
      monthlykm: yup.number().required("Monthly km is Required"),
      daykm: yup.number().required("Day km is Required"),
      weeklykm: yup.number().required("weeklykm is Required"),
      age: yup.string().required("age is Required"),
      insurence: yup.string().required("insurence is Required"),
      CDW: yup.string().required("CDW is Required"),
      VAT: yup.string().required("VAT is Required"),
      charge: yup.string().required("charge is Required"),
      claim: yup.string().required("Excess Claim is Required"),
      toll: yup.string().required("toll is Required"),
    }),

    onSubmit: async (values, action) => {
      const {
        brandId,
        categoryId,
        Carmodel,
        seat,
        door,
        BPH,
        maxspeed,
        fuel,
        geartype,
        day,
        weekly,
        monthly,
        monthlykm,
        daykm,
        weeklykm,
        age,
        insurence,
        CDW,
        VAT,
        charge,
        claim,
        toll,
      } = values;

      if (!values.id) {
        setLoading(true);

        setLoading(true);
        const response = await ModelService.addModel(
          id,
          categoryId,
          Carmodel,
          seat,
          door,
          BPH,
          maxspeed,
          fuel,
          geartype,
          day,
          weekly,
          monthly,
          monthlykm,
          daykm,
          weeklykm,
          age,
          insurence,
          CDW,
          VAT,
          charge,
          claim,
          toll
        );
        // console.log("response", response);
        setOpenModel(false);
        setLoading(false);
        brandDetail();
        action.resetForm();
      } else {
        setLoading(true);
        const response = await ModelService.updateModel(
          values.id,
          id,
          categoryId,
          Carmodel,
          seat,
          door,
          BPH,
          maxspeed,
          fuel,
          geartype,
          day,
          weekly,
          monthly,
          monthlykm,
          daykm,
          weeklykm,
          age,
          insurence,
          CDW,
          VAT,
          charge,
          claim,
          toll
        );
        // console.log(response);
        setOpenModel(false);
        setLoading(false);
        brandDetail();
        action.resetForm();
      }
    },
  });

  const allCategory = async () => {
    const response = await CategoryService.getAllCategory();
    setCategory(response.data.data.map((r) => r));
    ScrollToTop();
  };

  const brandDetail = async () => {
    const response = await BrandService.findOneBrand(id);
    setData(response.data);
    ScrollToTop();

    if (response.status === 200) {
      const getModels = await ModelService.getAllModels(
        response.data.brandname
      );
      setModels(getModels.data.data);
      console.log("Get all models>>>>>", getModels.data.data);
    }
  };

  const handleOpen = async (id, view) => {
    if (view) {
      setView(true);
    } else {
      setView(false);
    }
    setOpenModel(true);
    setUpdate(false);
    if (id) {
      setUpdate(true);
      const response = await ModelService.modelValue(id);
      console.log(response, "response");
      const result = response.data.data;

      setSelectedModelValue(result);
      setNewCategory(result.category);
      formik.setFieldValue("id", result.id);
      formik.setFieldValue("categoryId", result.categoryId);
      formik.setFieldValue("insurence", result.insurence);
      formik.setFieldValue("CDW", result.CDW);
      formik.setFieldValue("Carmodel", result.Carmodel);
      formik.setFieldValue("door", result.door);
      formik.setFieldValue("seat", result.seat);
      formik.setFieldValue("fuel", result.fuel);
      formik.setFieldValue("geartype", result.geartype);
      formik.setFieldValue("maxspeed", result.maxspeed);
      formik.setFieldValue("BPH", result.BPH);
      formik.setFieldValue("day", result.day);
      formik.setFieldValue("weekly", result.weekly);
      formik.setFieldValue("monthly", result.monthly);
      formik.setFieldValue("daykm", result.daykm);
      formik.setFieldValue("weeklykm", result.weeklykm);
      formik.setFieldValue("monthlykm", result.monthlykm);
      formik.setFieldValue("VAT", result.VAT);
      formik.setFieldValue("age", result.age);
      formik.setFieldValue("charge", result.charge);
      formik.setFieldValue("toll", result.toll);
      formik.setFieldValue("claim", result.claim);
    } else {
      formik.resetForm();
    }
  };

  const edit = () => {
    setView(false);
  };

  const openDeleteModel = (id) => {
    handleDialogOpen();
    setSelectedModelId(id);
  };

  const handleNegativeNumber = (e) => {
    const inputValue = parseInt(e.target.value);
    if (inputValue >= 0 || isNaN(inputValue)) {
      formik.handleChange(e);
    }
  };

  return (
    <div className="page-header">
      <div>
        <span className="text-dark tx-20">
          Brand : <span className="main-content-title">{data.brandname}</span>
        </span>
      </div>
      <div className="d-flex">
        <button
          className="btn btn-primary btn-sm ms-auto mb-2"
          onClick={() => handleOpen("")}
        >
          Add Model
        </button>
        {openModel && (
          <AddModel
            formik={formik}
            id={id}
            error={error}
            show={openModel}
            onHide={() => setOpenModel(false)}
            handleChangeCategory={handleChangeCategory}
            categories={categories}
            dropdown={dropdown}
            newCategory={newCategory}
            selectedCategory={selectedCategory}
            seletctInsurence={seletctInsurence}
            selectCDW={selectCDW}
            update={update}
            view={view}
            edit={edit}
            newCDW={newCDW}
            newInsurence={newInsurence}
            handleNegativeNumber={handleNegativeNumber}
          />
        )}
      </div>
      <Row className="row row-sm">
        {models == "" ? (
          <div className="mt-1 tx-danger tx-20">Models Not Found!</div>
        ) : (
          models.map((model, index) => (
            <Col sm={6} md={4} xl={2} xs={12} className="mb-5" key={index}>
              <Card
                className="custom-card mb-1 "
                style={{ cursor: "pointer", height: "100%" }}
              >
                <Card.Body>
                  <div className="p-0 ht-100p">
                    <div className="product-grid">
                      <div onClick={() => handleOpen(model.id, "View")}>
                        <h3 className="title text-primary">
                          {model.brand} &nbsp;{model.Carmodel}
                        </h3>
                        <div className="product-image">
                          <img
                            className="pic-1"
                            alt="product1"
                            style={{
                              position: "relative",
                              objectFit: "cover",
                            }}
                            src={model.categoryimage}
                          />
                        </div>
                        <Row className="product-content">
                          <div className="price justify-content-center">
                            <Col lg={12}>
                              <div className="text-dark">{model.category}</div>
                              <span className="text-danger">
                                <i className="si si-speedometer"></i>{" "}
                                {model.maxspeed} Max Speed
                              </span>
                            </Col>
                          </div>
                          <div className="price justify-content-center">
                            <div className="category-data">
                              <span className="old-price">
                                {model.start ? model.start : model.geartype}
                              </span>
                            </div>
                            <div className="category-data category-door">
                              <span className="old-price d-flex">
                                {model.door ? model.door : "--"} Doors
                              </span>
                            </div>
                          </div>
                          <div className="price justify-content-center">
                            <div className="category-data">
                              <span className="old-price">
                                {model.seat ? model.seat : "--"} Passengers
                              </span>
                            </div>
                            <div className="category-data category-door">
                              <span className="old-price d-flex">
                                {model.fuel ? model.fuel : "--"}
                              </span>
                            </div>
                          </div>
                        </Row>
                      </div>
                      <div className="d-flex">
                        <div
                          style={{
                            position: "absolute",
                            bottom: "10px",
                            right: "10px",
                          }}
                        >
                          <button
                            variant="danger"
                            className="btn btn-primary btn-sm me-1 ms-auto"
                            onClick={() => handleOpen(model.id)}
                            style={{ marginRight: "5px" }}
                          >
                            <i className="fe fe-edit"></i>
                          </button>
                          <button
                            className="btn btn-danger btn-sm"
                            onClick={() => openDeleteModel(model.id)}
                            style={{ marginRight: "5px" }}
                          >
                            <i className="fa fa-trash"></i>
                          </button>
                        </div>
                        {isDialogOpen && selectedModelId === model.id && (
                          <DeleteModel
                            id={model.id}
                            brandDetail={brandDetail}
                            show={isDialogOpen}
                            onHide={handleDialogClose}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))
        )}
      </Row>
    </div>
  );
};

export default withLoader(BrandDetails);
