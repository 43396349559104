import serverCall from "../../serverCall";
import { get } from "lodash";
import { enqueueSnackbar } from "notistack";

const Login = async (loginPage) => {
  const { email, password } = loginPage;
  const response = await serverCall.post(
    `/admin/login-admin?email=${email}&password=${password}`
  );
  if (response.data.status === 400 || response.data.status === 401) {
    enqueueSnackbar(response.data.message, { variant: "error" });
  } else {
    const data = get(response, "data.accessToken", null);
    const Email = get(response, "data.Admin.email", null);
    if ((data, Email)) {
      localStorage.setItem("token", (data, "jwtToken", data));
      localStorage.setItem("email", get(Email, "email", Email));
    }
    return response;
  }
};

const logout = () => {
  localStorage.removeItem("token");
  localStorage.clear();
};

const AuthService = {
  Login,
  logout,
};

export default AuthService;
