import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import LocationService from "../../services/location.service";
import { useFormik } from "formik";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { Button, Card, Form, Modal, Row } from "react-bootstrap";
import useDialogState from "../../hooks/useDialog";
import moment from "moment";
import withLoader from "../../Layout/Loader/WithLoader";
import { ScrollToTop } from "../Common/ScrollToTop";
import { CircularProgress } from "@mui/material";

const error = {
  marginTop: "8px",
  color: "#FD481B",
  fontSize: "13px",
  fontWeight: "500px",
};

const Currency = () => {
  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    open: isDialogOpen,
    handleClickOpen: handleDialogOpen,
    handleClose: handleDialogClose,
  } = useDialogState();

  const deleteCurrency = (_id) => {
    setId(_id);
    setDeleteModal(true);
  };

  const getCurrency = async () => {
    const response = await LocationService.getCurrency();
    setData(response.data.data);
    ScrollToTop();
    console.log(response);
  };

  useEffect(() => {
    getCurrency();
  }, []);

  const formik = useFormik({
    initialValues: {
      country: "",
      currency: "",
    },
    validationSchema: yup.object({
      country: yup.string().required("Country is Required"),
      currency: yup.string().required("Currency is Required"),
    }),
    onSubmit: async (values, action) => {
      const { country, currency } = values;

      const upperCaseCurrency = currency.toUpperCase();

      if (!values.id) {
        setLoading(true);
        const response = await LocationService.addCurrency(
          country,
          upperCaseCurrency
        );
        console.log(response);
        setLoading(false);
        closeDialog();
        getCurrency();
      } else {
        setLoading(true);
        const response = await LocationService.updateCurrency(
          values.id,
          country,
          upperCaseCurrency
        );
        console.log(response);
        setLoading(false);
        closeDialog();
        getCurrency();
      }
    },
  });

  const columns = [
    {
      name: <strong>ID</strong>,
      selector: (row) => [row._id],
      sortable: true,
    },
    {
      name: <strong>COUNTRY</strong>,
      selector: (row) => [row.country],
      sortable: true,
    },
    {
      name: <strong>CURRENCY</strong>,
      selector: (row) => [row.currency],
      sortable: true,
    },
    {
      name: <strong>ADMIN ID</strong>,
      selector: (row) => [row.AdminId],
      sortable: true,
    },
    {
      name: <strong>CREATION DATE</strong>,
      selector: (row) => [moment(row.createdAt).format("DD/MM/YYYY")],
      sortable: true,
    },
    {
      name: <strong>ACTION</strong>,
      selector: (row) => (
        <>
          <Link
            className="btn btn-primary p-2 btn-sm me-2"
            onClick={() => addNewCurrency(row._id, row.country, row.currency)}
          >
            <i className="fe fe-edit fs-14"></i>
          </Link>
          <button
            className="btn btn-danger p-2 btn-sm"
            onClick={() => deleteCurrency(row._id)}
          >
            <i className="fe fe-trash fs-14"></i>
          </button>
        </>
      ),
      sortable: true,
    },
  ];

  const tableData = {
    columns,
    data,
  };

  const addNewCurrency = (id, country, currency) => {
    handleDialogOpen();
    if (id) {
      console.log(id, country, currency);
      formik.setFieldValue("id", id);
      formik.setFieldValue("country", country);
      formik.setFieldValue("currency", currency);
    } else {
      formik.setFieldValue("id", "");
      formik.setFieldValue("country", "");
      formik.setFieldValue("currency", "");
    }
  };

  const closeDialog = () => {
    handleDialogClose();
    formik.resetForm();
  };

  return (
    <div className="page-header">
      <h2 className="main-content-title tx-24 mb-3 mt-2">Currency</h2>
      <Card>
        <div className="p-3">
          <button
            variant="danger"
            className="btn btn-primary btn-sm mt-3"
            onClick={() => addNewCurrency("")}
          >
            <i className="fe fe-plus fs-14"></i> Add Currency
          </button>
          {isDialogOpen && (
            <AddCurrency
              show={isDialogOpen}
              onHide={closeDialog}
              formik={formik}
              loading={loading}
            />
          )}
          {deleteModal && (
            <DeleteCurrency
              show={deleteModal}
              onHide={() => setDeleteModal(false)}
              getCurrency={getCurrency}
              id={id}
            />
          )}
          <DataTableExtensions {...tableData}>
            <DataTable
              title="Counsellors"
              columns={columns}
              data={data}
              noHeader
              defaultSortField="id"
              defaultSortAsc={false}
              striped={true}
              center={true}
              persistTableHead
              pagination
              highlightOnHover
            />
          </DataTableExtensions>
        </div>
      </Card>
    </div>
  );
};

export default withLoader(Currency);

const AddCurrency = (props) => {
  const { formik, loading } = props;

  return (
    <>
      <Modal
        {...props}
        size="large"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <h4 className="tx-semibold  mt-2 ms-auto">ADD CURRENCY</h4>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <Row>
              <Form.Group className="form-group">
                <Form.Control
                  type="text"
                  name="country"
                  value={formik.values.country}
                  onChange={formik.handleChange}
                  placeholder="Country Name"
                />
                {formik.errors.country && formik.touched.country ? (
                  <p style={error}>{formik.errors.country}</p>
                ) : null}
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Control
                  type="text"
                  name="currency"
                  value={formik.values.currency.toUpperCase()}
                  onChange={formik.handleChange}
                  placeholder="Currency"
                />
                {formik.errors.currency && formik.touched.currency ? (
                  <p style={error}>{formik.errors.currency}</p>
                ) : null}
              </Form.Group>
              <Form.Group className="form-group">
                {loading ? (
                  <button className="btn btn-primary btn-sm d-flex ms-auto">
                    <CircularProgress
                      style={{
                        width: "25px",
                        height: "25px",
                        color: "#ffffff",
                      }}
                    />
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-primary btn-sm d-flex ms-auto"
                  >
                    Add Currency
                  </button>
                )}
              </Form.Group>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

const DeleteCurrency = (props) => {
  const [loading, setLoading] = useState(false);
  const deleteCurrency = async () => {
    setLoading(true);
    const response = await LocationService.deleteLocation(props.id);
    console.log(response);

    setLoading(false);
    props.getCurrency();
    props.onHide();
  };

  return (
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton />
        <Modal.Body className="text-center">
          <i className="fe fe-trash tx-100 tx-danger"></i>
          <h4 className="tx-danger tx-semibold ">Delete?</h4>
          <p className="mg-x-20">Are you sure want to delete location ?</p>
          {loading ? (
            <Button type="button" variant="danger">
              <CircularProgress
                style={{
                  width: "25px",
                  height: "25px",
                  color: "#ffffff",
                }}
              />
            </Button>
          ) : (
            <Button
              type="button"
              onClick={() => deleteCurrency(props.id)}
              variant="danger"
            >
              Delete
            </Button>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};
