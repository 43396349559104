import serverCall from "../../serverCall";

const getAllOwners = async () => {
  const response = await serverCall.get(`/all-Owners/admin`);
  return response;
};

const ownerProfile = async (id) => {
  const response = await serverCall.get(`/Owner-detail/admin?OwnerId=${id}`);
  return response;
};

const createOwner = (
  mobileNumber,
  countryCode,
  email,
  businessName,
  about,
  dateOfBirth,
  isOnline,
  isPostNotify
) => {
  const response = serverCall.post(`/create-owner/admin`, {
    mobileNumber,
    countryCode,
    email,
    businessName,
    about,
    dateOfBirth,
    isOnline,
    isPostNotify,
  });
  console.log(response);
  return response;
};

const updateOwner = (
  id,
  mobileNumber,
  countryCode,
  email,
  businessName,
  about
) => {
  const response = serverCall.patch(`/update-owner/admin?ownerID=${id}`, {
    mobileNumber,
    countryCode,
    email,
    businessName,
    about,
  });
  console.log(response);
  return response;
};

const deleteOwner = (id) => {
  const response = serverCall.delete(`/delete-owner/admin?ownerID=${id}`);
  console.log(response);
  return response;
};

const restrictOwner = async (OwnerId, isRestricted) => {
  const response = await serverCall.post(
    `/RestrictedOwner/admin?OwnerId=${OwnerId}&isRestricted=${isRestricted}`
  );
  return response;
};

const documentsRequire = (ownerID, local, tourist) => {
  const response = serverCall.post(
    `/Document-require/admin?ownerID=${ownerID}`,
    { local, tourist }
  );
  return response;
};

const ownerPhoto = (ownerID, photo) => {
  const response = serverCall.post(
    `/addPhoto-in-owner/admin?ownerID=${ownerID}`,
    photo
  );
  return response;
};

const OwnerService = {
  getAllOwners,
  ownerProfile,
  restrictOwner,
  createOwner,
  updateOwner,
  deleteOwner,
  documentsRequire,
  ownerPhoto,
};

export default OwnerService;
