import React, { Fragment, useEffect, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { ScrollToTop } from "../Common/ScrollToTop";
import LocationService from "../../services/location.service";
import DataTable from "react-data-table-component";
import moment from "moment";
import { Link } from "react-router-dom";
import Multiselect from "react-select";
import OwnerService from "../../services/owner.service";

const TransactionDetails = () => {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState("ALL");
  const [totalTransactions, setTotalTransactions] = useState("");
  const [totalRevenue, setTotalRevenue] = useState("");

  const periodValidity = [
    {
      value: "ALL",
      label: "ALL",
    },
    {
      value: "DAILY",
      label: "DAILY",
    },
    {
      value: "WEEKLY",
      label: "WEEKLY",
    },
    {
      value: "MONTHLY",
      label: "MONTHLY",
    },
  ];

  const getData = async () => {
    // setLoading(true);
    const response = await LocationService.transactionDetails(filter);
    setData(response.data.data);
    setTotalTransactions(response.data.TotalTransaction);
    setTotalRevenue(response.data.totalRevenue);
    console.log(response.data);
  };

  useEffect(() => {
    ScrollToTop();
    getData();
  }, [filter]);

  const columns = [
    {
      name: <strong>OWNER NAME</strong>,
      selector: (row) => [row.OwnerName ? row.OwnerName : "--"],
      sortable: true,
    },
    {
      name: <strong>TRANSACTION ID</strong>,
      selector: (row) => [row.transactioId ? row.transactioId : "--"],
      sortable: true,
    },
    {
      name: <strong>AMOUNT</strong>,
      selector: (row) => [row.amount ? row.amount : "--"],
      sortable: true,
    },
    {
      name: <strong>OWNER ID</strong>,
      selector: (row) => [row.OwnerId ? row.OwnerId : "--"],
      sortable: true,
    },
    {
      name: <strong>FEATURE PERIOD</strong>,
      selector: (row) => [row.feature_period ? row.feature_period : "--"],
      sortable: true,
    },
    {
      name: <strong>CREATED</strong>,
      selector: (row) => [moment(row.createdAt).format("DD-MM-YYYY")],
      sortable: true,
    },
    {
      name: <strong>ACTIONS</strong>,
      selector: (row) => (
        <>
          <Link
            className="btn btn-primary p-2 btn-sm me-2"
            to={`/carowner/carownerprofile/${row.OwnerId}`}
          >
            <i className="fe fe-eye fs-14"></i>
          </Link>
        </>
      ),
      sortable: true,
    },
  ];

  return (
    <Fragment>
      <div className="page-header">
        <h2 className="main-content-title tx-24 mb-1 mt-2">
          Transaction Details
        </h2>
        {/* <!--Row--> */}

        <Row className="row-sm">
          <Col sm={12} lg={12} xl={12}>
            {/* <!--Row--> */}
            <Row className="row-sm mt-lg-4">
              <Col sm={12} md={12} lg={12} xl={4}>
                <Card className="card custom-card">
                  <Card.Body>
                    <div className="card-item">
                      <div className="card-item-icon card-icon">
                        <i
                          className="fas fa-wallet fs-20"
                          style={{ color: "#6259CA" }}
                        ></i>
                      </div>
                      <div className="card-item-title  mb-2">
                        <label className="main-content-label tx-13 font-weight-bold mb-1">
                          Total Transactions
                        </label>
                        <span className="d-block tx-12 mb-0 text-muted">
                          Total Transaction Initiated
                        </span>
                      </div>
                      <div className="card-item-body">
                        <div className="card-item-stat">
                          <h4 className="font-weight-bold">
                            {totalTransactions}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={12} md={12} lg={12} xl={4}>
                <Card className="card custom-card">
                  <Card.Body>
                    <div className="card-item">
                      <div className="card-item-icon card-icon">
                        <i
                          className="fa fa-dollar-sign fs-18"
                          style={{ color: "#6259CA" }}
                        ></i>
                      </div>
                      <div className="card-item-title  mb-2">
                        <label className="main-content-label tx-13 font-weight-bold mb-1">
                          Total Revenue
                        </label>
                        <span className="d-block tx-12 mb-0 text-muted">
                          Total Revenue Initiated
                        </span>
                      </div>
                      <div className="card-item-body">
                        <div className="card-item-stat">
                          <h4 className="font-weight-bold">
                            {totalRevenue ? totalRevenue : "0"}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            {/* <!--End row--> */}
            {/* <!--row--> */}
            <Card>
              <div className="p-3">
                <Row>
                  <Col md={2}>
                    <Form.Group className="form-group">
                      <Multiselect
                        classNamePrefix="Select2"
                        options={periodValidity}
                        singleSelect
                        placeholder="Period Validity"
                        displayValue="key"
                        name="periodvalidity"
                        onChange={(selectedOption) =>
                          setFilter(selectedOption.value)
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <div>
                  {/* <DataTableExtensions {...tableData}> */}
                  <DataTable
                    title="Car Owners"
                    columns={columns}
                    data={data}
                    noHeader
                    defaultSortField="id"
                    defaultSortAsc={false}
                    striped={true}
                    center={true}
                    persistTableHead
                    pagination
                    highlightOnHover
                    // paginationTotalRows={totalTransactions}
                  />
                  {/* </DataTableExtensions> */}
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default TransactionDetails;
