import { Col, FormGroup, Row, Form, Modal, Button } from "react-bootstrap";
import Multiselect from "react-select";
import { CircularProgress } from "@mui/material";
import ModelService from "../../services/model.service";

const AddModel = (props) => {
  const {
    categories,
    update,
    formik,
    error,
    newCategory,
    selectCDW,
    selectedCategory,
    handleChangeCategory,
    dropdown,
    newCDW,
    newInsurence,
    seletctInsurence,
    loading,
    view,
    edit,
    handleNegativeNumber,
  } = props;

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton />

      {view ? (
        <>
          <h4 className="tx-semibold text-center mt-2 text-dark">VIEW MODEL</h4>
          <button
            className="btn btn-primary btn-sm ms-auto me-4"
            onClick={edit}
          >
            Edit
          </button>
        </>
      ) : (
        <h4 className="tx-semibold text-center mt-2 text-dark">
          {update ? "UPDATE MODEL" : "ADD MODEL"}
        </h4>
      )}
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <Row>
            <p
              className="tx-18 text-dark"
              style={{ textTransform: "uppercase" }}
            >
              Car Information
            </p>
            <Col md={3}>
              <FormGroup className="form-group">
                <Form.Label className="tx-medium">Car Model</Form.Label>
                <input
                  type="text"
                  name="Carmodel"
                  id="Carmodel"
                  value={formik.values.Carmodel}
                  onChange={formik.handleChange}
                  className="form-control"
                  placeholder="Car Model"
                  disabled={view ? true : false}
                />
                {formik.errors.Carmodel && formik.touched.Carmodel ? (
                  <p style={error}>{formik.errors.Carmodel}</p>
                ) : null}
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup className="form-group">
                <Form.Label className="tx-medium">Category</Form.Label>
                <Multiselect
                  classNamePrefix="Select2"
                  options={categories}
                  defaultInputValue={newCategory}
                  singleSelect
                  placeholder="Category"
                  displayValue="key"
                  name="categoryId"
                  value={selectedCategory}
                  onChange={handleChangeCategory}
                  isDisabled={view ? true : false}
                />
                {formik.errors.categoryId && formik.touched.categoryId ? (
                  <p style={error}>{formik.errors.categoryId}</p>
                ) : null}
              </FormGroup>{" "}
            </Col>
            <Col md={3}>
              <FormGroup className="form-group">
                <Form.Label className="tx-medium">Door</Form.Label>
                <input
                  type="number"
                  name="door"
                  id="door"
                  value={formik.values.door}
                  onChange={handleNegativeNumber}
                  className="form-control"
                  placeholder="4"
                  disabled={view ? true : false}
                />
                {formik.errors.door && formik.touched.door ? (
                  <p style={error}>{formik.errors.door}</p>
                ) : null}
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup className="form-group">
                <Form.Label className="tx-medium">Passengers</Form.Label>
                <input
                  type="number"
                  name="seat"
                  id="seat"
                  value={formik.values.seat}
                  onChange={handleNegativeNumber}
                  className="form-control"
                  placeholder="4"
                  disabled={view ? true : false}
                />
                {formik.errors.seat && formik.touched.seat ? (
                  <p style={error}>{formik.errors.seat}</p>
                ) : null}
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup className="form-group">
                <Form.Label className="tx-medium">Fuel Type</Form.Label>
                <input
                  type="text"
                  name="fuel"
                  id="fuel"
                  value={formik.values.fuel}
                  onChange={formik.handleChange}
                  className="form-control"
                  placeholder="Diesel"
                  disabled={view ? true : false}
                />
                {formik.errors.fuel && formik.touched.fuel ? (
                  <p style={error}>{formik.errors.fuel}</p>
                ) : null}
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup className="form-group">
                <Form.Label className="tx-medium">Gear Type</Form.Label>
                <input
                  type="text"
                  name="geartype"
                  id="geartype"
                  value={formik.values.geartype}
                  onChange={formik.handleChange}
                  className="form-control"
                  placeholder="Manual"
                  disabled={view ? true : false}
                />
                {formik.errors.geartype && formik.touched.geartype ? (
                  <p style={error}>{formik.errors.geartype}</p>
                ) : null}
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup className="form-group">
                <Form.Label className="tx-medium">Max. Speed km/h</Form.Label>
                <input
                  type="number"
                  name="maxspeed"
                  id="maxspeed"
                  value={formik.values.maxspeed}
                  onChange={handleNegativeNumber}
                  className="form-control"
                  placeholder="140"
                  disabled={view ? true : false}
                />
                {formik.errors.maxspeed && formik.touched.maxspeed ? (
                  <p style={error}>{formik.errors.maxspeed}</p>
                ) : null}
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup className="form-group">
                <Form.Label className="tx-medium">BHP</Form.Label>
                <input
                  type="number"
                  name="BPH"
                  id="BPH"
                  value={formik.values.BPH}
                  onChange={handleNegativeNumber}
                  className="form-control"
                  placeholder="120"
                  disabled={view ? true : false}
                />
                {formik.errors.BPH && formik.touched.BPH ? (
                  <p style={error}>{formik.errors.BPH}</p>
                ) : null}
              </FormGroup>
            </Col>

            <hr className="mt-3" />

            <p
              className="tx-18 mt-2 text-dark"
              style={{ textTransform: "uppercase" }}
            >
              Other Details
            </p>

            <Col md={3}>
              <FormGroup className="form-group">
                <Form.Label className="tx-medium">Price / Day</Form.Label>
                <input
                  type="number"
                  name="day"
                  id="day"
                  value={formik.values.day}
                  onChange={handleNegativeNumber}
                  className="form-control"
                  placeholder="200"
                  disabled={view ? true : false}
                />
                {formik.errors.day && formik.touched.day ? (
                  <p style={error}>{formik.errors.day}</p>
                ) : null}
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup className="form-group">
                <Form.Label className="tx-medium">Price / Week</Form.Label>
                <input
                  type="number"
                  name="weekly"
                  id="weekly"
                  value={formik.values.weekly}
                  onChange={handleNegativeNumber}
                  className="form-control"
                  placeholder="200"
                  disabled={view ? true : false}
                />
                {formik.errors.weekly && formik.touched.weekly ? (
                  <p style={error}>{formik.errors.weekly}</p>
                ) : null}
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup className="form-group">
                <Form.Label className="tx-medium">Price / Month</Form.Label>
                <input
                  type="number"
                  name="monthly"
                  id="monthly"
                  value={formik.values.monthly}
                  onChange={handleNegativeNumber}
                  className="form-control"
                  placeholder="200"
                  disabled={view ? true : false}
                />
                {formik.errors.monthly && formik.touched.monthly ? (
                  <p style={error}>{formik.errors.monthly}</p>
                ) : null}
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup className="form-group">
                <Form.Label className="tx-medium">Kms / Day</Form.Label>
                <input
                  type="number"
                  name="daykm"
                  id="daykm"
                  value={formik.values.daykm}
                  onChange={handleNegativeNumber}
                  className="form-control"
                  placeholder="250"
                  disabled={view ? true : false}
                />
                {formik.errors.daykm && formik.touched.daykm ? (
                  <p style={error}>{formik.errors.daykm}</p>
                ) : null}
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup className="form-group">
                <Form.Label className="tx-medium">Kms / Week</Form.Label>
                <input
                  type="number"
                  name="weeklykm"
                  id="weeklykm"
                  value={formik.values.weeklykm}
                  onChange={handleNegativeNumber}
                  className="form-control"
                  placeholder="4500"
                  disabled={view ? true : false}
                />
                {formik.errors.weeklykm && formik.touched.weeklykm ? (
                  <p style={error}>{formik.errors.weeklykm}</p>
                ) : null}
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup className="form-group">
                <Form.Label className="tx-medium">Kms / Month</Form.Label>
                <input
                  type="number"
                  name="monthlykm"
                  id="monthlykm"
                  value={formik.values.monthlykm}
                  onChange={handleNegativeNumber}
                  className="form-control"
                  placeholder="4500"
                  disabled={view ? true : false}
                />
                {formik.errors.monthlykm && formik.touched.monthlykm ? (
                  <p style={error}>{formik.errors.monthlykm}</p>
                ) : null}
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup className="form-group">
                <Form.Label className="tx-medium">VAT</Form.Label>
                <input
                  type="number"
                  name="VAT"
                  id="VAT"
                  value={formik.values.VAT}
                  onChange={handleNegativeNumber}
                  className="form-control"
                  placeholder="5%"
                  disabled={view ? true : false}
                />
                {formik.errors.VAT && formik.touched.VAT ? (
                  <p style={error}>{formik.errors.VAT}</p>
                ) : null}
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup className="form-group">
                <Form.Label className="tx-medium">Age</Form.Label>
                <input
                  type="number"
                  name="age"
                  id="age"
                  value={formik.values.age}
                  onChange={handleNegativeNumber}
                  className="form-control"
                  placeholder="18"
                  disabled={view ? true : false}
                />
                {formik.errors.age && formik.touched.age ? (
                  <p style={error}>{formik.errors.age}</p>
                ) : null}
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup className="form-group">
                <Form.Label className="tx-medium">Insurance</Form.Label>
                <Multiselect
                  classNamePrefix="Select2"
                  options={dropdown}
                  defaultValue={newInsurence}
                  singleSelect
                  placeholder="Insurance"
                  displayValue="key"
                  name="insurence"
                  onChange={seletctInsurence}
                  isDisabled={view ? true : false}
                />
                {formik.errors.insurence && formik.touched.insurence ? (
                  <p style={error}>{formik.errors.insurence}</p>
                ) : null}
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup className="form-group">
                <Form.Label className="tx-medium">CDW</Form.Label>
                <Multiselect
                  classNamePrefix="Select2"
                  options={dropdown}
                  defaultValue={newCDW}
                  singleSelect
                  placeholder="CDW"
                  displayValue="key"
                  name="CDW"
                  onChange={selectCDW}
                  isDisabled={view ? true : false}
                />
                {formik.errors.CDW && formik.touched.CDW ? (
                  <p style={error}>{formik.errors.CDW}</p>
                ) : null}
              </FormGroup>
            </Col>

            <hr className="mt-3" />

            <p
              className="tx-18 mt-2 text-dark"
              style={{ textTransform: "uppercase" }}
            >
              Payment Details
            </p>

            <Col md={3}>
              <FormGroup className="form-group">
                <Form.Label className="tx-medium">
                  Additional Charge / Km
                </Form.Label>
                <input
                  type="number"
                  name="charge"
                  id="charge"
                  value={formik.values.charge}
                  onChange={handleNegativeNumber}
                  className="form-control"
                  placeholder="5"
                  disabled={view ? true : false}
                />
                {formik.errors.charge && formik.touched.charge ? (
                  <p style={error}>{formik.errors.charge}</p>
                ) : null}
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup className="form-group">
                <Form.Label className="tx-medium">Toll Charge AED</Form.Label>
                <input
                  type="number"
                  name="toll"
                  id="toll"
                  value={formik.values.toll}
                  onChange={handleNegativeNumber}
                  className="form-control"
                  placeholder="5"
                  disabled={view ? true : false}
                />
                {formik.errors.toll && formik.touched.toll ? (
                  <p style={error}>{formik.errors.toll}</p>
                ) : null}
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup className="form-group">
                <Form.Label className="tx-medium">Claim AED</Form.Label>
                <input
                  type="number"
                  name="claim"
                  id="claim"
                  value={formik.values.claim}
                  onChange={handleNegativeNumber}
                  className="form-control"
                  placeholder="5000"
                  disabled={view ? true : false}
                />
                {formik.errors.claim && formik.touched.claim ? (
                  <p style={error}>{formik.errors.claim}</p>
                ) : null}
              </FormGroup>{" "}
            </Col>
          </Row>

          {view ? (
            ""
          ) : (
            <div className="card-footer d-flex">
              {loading ? (
                <button className="btn btn-primary ms-auto me-1">
                  <CircularProgress
                    style={{ width: "25px", height: "25px", color: "#ffffff" }}
                  />
                </button>
              ) : (
                <button className="btn btn-primary ms-auto me-1" type="submit">
                  {update ? "Update Model" : "Add Model"}
                </button>
              )}
              <button onClick={() => props.onHide()} className="btn btn-danger">
                Cancel
              </button>
            </div>
          )}
        </form>
      </Modal.Body>
    </Modal>
  );
};

AddModel.propTypes = {};

AddModel.defaultProps = {};

export default AddModel;

export const DeleteModel = (props) => {
  const { id, brandDetail } = props;
  console.log("Delete id", id);

  const deleteModelId = async () => {
    const response = await ModelService.deleteModel(id);
    console.log("delete", response);
    brandDetail();
  };

  return (
    <Modal
      {...props}
      size="large"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton />
      <Modal.Body className="text-center">
        <i className="icon fe fe-trash tx-100 tx-danger  mg-t-20 "></i>

        <h4 className="tx-danger tx-semibold mg-b-20">Reminder!</h4>
        <p className="mg-b-20 mg-x-20">Are you sure to delete this owner?</p>
        {/* {loading ? (
          <Button variant="danger">
            <CircularProgress
              style={{ width: "25px", height: "25px", color: "#ffffff" }}
            />
          </Button>
        ) : ( */}
        <Button onClick={() => deleteModelId(id)} variant="danger">
          DELETE
        </Button>
        {/* )} */}
      </Modal.Body>
    </Modal>
  );
};
