import serverCall from "../../serverCall";

const getAllCategory = async () => {
  const response = await serverCall.get(`/category/All-Category-List/admin`);
  return response;
};

const createCategory = async (
  categoryname,
  fuel,
  geartype,
  seat,
  door,
  maxspeed,
  BPH
) => {
  const response = await serverCall.post(`/category/create-Category/admin`, {
    categoryname: categoryname,
    fuel: fuel,
    geartype: geartype,
    seat: seat,
    door: door,
    maxspeed: maxspeed,
    BPH: BPH,
  });
  return response;
};

const updateCategory = async (CategoryId, category) => {
  const response = await serverCall.post(
    `/category/update-details/admin?CategoryId=${CategoryId}`,
    category
  );
  return response;
};

const addPhotos = async (CategoryId, category) => {
  const response = await serverCall.post(
    `/category/addPhotos/admin?CategoryId=${CategoryId}`,
    category
  );
  return response;
};

const findOneCategory = (id) => {
  const response = serverCall.get(
    `/category/Find-One-Category?CategoryId=${id}`
  );
  return response;
};

const deleteCategory = async (CategoryId) => {
  const response = await serverCall.delete(
    `/category/Delete-Category/admin?CategoryId=${CategoryId}`
  );
  return response;
};

const deleteCategoryImage = async (CategoryId) => {
  const response = await serverCall.delete(
    `/category/Delete-image/admin?CategoryId=${CategoryId}`
  );
  return response;
};

const CategoryService = {
  getAllCategory,
  createCategory,
  deleteCategory,
  addPhotos,
  updateCategory,
  findOneCategory,
  deleteCategoryImage,
};

export default CategoryService;
