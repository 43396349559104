import serverCall from "../../serverCall";

const ownersLocation = async (ownerId, location) => {
  const response = await serverCall.post(
    `/ownerslocation/admin-location-update/admin?ownerId=${ownerId}&location=${location}`);
  return response;
};

const ownersgetLocation = async (id) => {
  const response = await serverCall.get(`Owner-detail/admin?OwnerId=${id}`);
  return response;
};

const addLocation = async (location, loc) => {
  const response = await serverCall.post(
    `/locations/add-location/admin?location=${location}`,
    loc
  );
  return response;
};

const updateLocation = async (id, location, loc) => {
  const response = await serverCall.patch(
    `/locations/update-location/admin/{id}?id=${id}&location=${location}`,
    loc
  );
  return response;
};
const deleteLocation = async (id) => {
  const response = await serverCall.delete(
    `/locations/delete-location/admin/{id}?id=${id}`
  );
  return response;
};

const getLocations = () => {
  const response = serverCall.get(`/locations/fetch-all-locations`);
  return response;
};

const getCurrency = () => {
  const response = serverCall.get(`/locations/fetch-all-currency`);
  return response;
};

const addCurrency = (country, currency) => {
  const response = serverCall.post(
    `/locations/add-currency/admin?country=${country}&currency=${currency}`
  );
  return response;
};

const transactionDetails = (period) => {
  const response = serverCall.get(
    `/payment/all-transaction-detail/admin?feature_period=${period}`
  );
  return response;
};

const updateCurrency = (id, country, currency) => {
  const response = serverCall.patch(
    `/locations/update-currency/admin/{id}?id=${id}&country=${country}&currency=${currency}`
  );
  return response;
};

const LocationService = {
  ownersLocation,
  getLocations,
  addLocation,
  updateLocation,
  deleteLocation,
  getCurrency,
  addCurrency,
  updateCurrency,
  transactionDetails,
  ownersgetLocation,
};

export default LocationService;
