import React from "react";
import { styled } from "@mui/styles";
import { Box } from "@mui/material";
import Loader1 from "../../../assets/img/loader.svg";

const StyledLoading = styled("div")(() => ({
  width: "100%",
  height: "100%",
  position: "absolute",
  top: 0,
  left: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#eaedf7",
  zIndex: 1,
  marginTop: "65px",
}));

const Loader = () => (
  <StyledLoading>
    <Box position="relative">
      <img src={Loader1} alt="Loader" />
    </Box>
  </StyledLoading>
);
Loader.propTypes = {};
Loader.defaultProps = {};
export default Loader;

// import React from "react";
// import Loader1 from "../../../assets/img/loader.svg";
// import { styled } from "@mui/material/styles";
// import { Box } from "@mui/material";

// const StyledLoading = styled("div")(() => ({
//   width: "100%",
//   height: "100%",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
//   "& img": {
//     width: "auto",
//     height: "70px",
//   },
//   "& .circleProgress": {
//     position: "absolute",
//     zIndex: 5000000,
//     left: 0,
//     right: "calc(100% - 240px)",
//     top: "100%",
//   },
// }));

// const Loader = () => (
//   <StyledLoading
//     style={{
//       marginTop: "45vh",
//     }}
//   >
//     <Box position="relative">
//       <img src={Loader1} alt="Loader" />
//     </Box>
//   </StyledLoading>
// );

// Loader.propTypes = {};

// Loader.defaultProps = {};
// export default Loader;
